import React from 'react'

export default function IndexPage() {
  return (
    <div>
      
    </div>
  )
}


// import React from "react";
// import { Link } from "gatsby";

// const PostLink = ({ post }) => (
//   <div>
//     <Link to={post.frontmatter.id}>
//       {post.frontmatter.title} ({post.frontmatter.date})
//     </Link>
//   </div>
// );

// const IndexPage = ({
//   data: {
//     allM: { edges }
//   }
// }) => {
//   const Posts = edges
//     .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
//     .map(edge => <PostLink key={edge.node.id} post={edge.node} />);

//   return <div>{Posts}</div>;
// };
// export default IndexPage;

// export const pageQuery = graphql`
//   query IndexQuery {
//     allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
//       edges {
//         node {
//           id
//           excerpt(pruneLength: 250)
//           frontmatter {
//             title
//             date(formatString: "MMMM DD, YYYY")
//             desc
//             writer
//             writerId
//             difficulty
//             category
//             img
//             demourl
//             source
//             language
//             id
//             embed
//             embedURL
//           }
//         }
//       }
//     }
//   }
// `;
